/*--------------------------------------------------------------
|                Button Privacy Policy Hover                   |
--------------------------------------------------------------*/
document.getElementById('button__privacy').addEventListener('mouseover', function () {
    document.getElementById('privacy').style.opacity = '0';
    document.getElementById('figure__privacy').style.flexDirection = 'row';
    document.getElementById('privacy-hover').style.opacity = '1';
});

document.getElementById('button__privacy').addEventListener('mouseout', function () {
    document.getElementById('privacy').style.opacity = '1';
    document.getElementById('figure__privacy').style.flexDirection = 'row-reverse';
    document.getElementById('privacy-hover').style.opacity = '0';
});


/*--------------------------------------------------------------
|                    Button Contact Us Hover                   |
--------------------------------------------------------------*/
document.getElementById('button__contact-us').addEventListener('mouseover', function () {
    document.getElementById('mail').style.opacity = '0';
    document.getElementById('figure__mail').style.flexDirection = 'row';
    document.getElementById('mail-hover').style.opacity = '1';
});

document.getElementById('button__contact-us').addEventListener('mouseout', function () {
    document.getElementById('mail').style.opacity = '1';
    document.getElementById('figure__mail').style.flexDirection = 'row-reverse';
    document.getElementById('mail-hover').style.opacity = '0';
});



















/*--------------------------------------------------------------
|                 Identify language and change                 |
--------------------------------------------------------------*/
function updateLanguage(languages) {
    const missionElement = document.querySelector(".container__mission");
    const contactButton = document.querySelector(".button__text");
    const privacyPolicy = document.querySelector(".button__text-privacy");

    const isAllowedToRenderPortuguese = languages.length > 0 && (languages[0] === "pt-BR" || languages[0] === "pt")
    if (isAllowedToRenderPortuguese) {
        missionElement.innerHTML = "Nossa missão é fazer com que a humanidade supere suas limitações e maiores desafios por meio da Tecnologia e Inteligência Artificial";
        contactButton.textContent = "Contate-nos";
        privacyPolicy.textContent = "Política de Privacidade"

    }
}

/*--------------------------------------------------------------
|                  Identify year for Copyright                 |
--------------------------------------------------------------*/
document.getElementById('currentYear').textContent = new Date().getFullYear();


